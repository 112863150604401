;(function() {
  'use strict'

  Controller.$inject = ["hostingCreditService"];
  angular.module('app.core').component('addHostingCreditDialog', {
    controller: Controller,
    templateUrl: 'add-hosting-credit-dialog.html',
    bindings: {
      subscriber: '<',
      onDone: '&',
      onCancel: '&',
    },
  })

  /* @ngInject */
  function Controller(hostingCreditService) {
    var ctrl = this

    ctrl.$onInit = onInit
    ctrl.canAdd = canAdd
    ctrl.add = add

    function onInit() {
      ctrl.amount = 0
      ctrl.reason = ''
    }

    function canAdd() {
      return (
        _.isNumber(ctrl.amount) &&
        ctrl.amount > 0 &&
        ctrl.reason &&
        ctrl.reason.length > 4
      )
    }

    function add() {
      if (ctrl.loading || !ctrl.canAdd()) return
      ctrl.error = null
      ctrl.loading = true
      hostingCreditService
        .topUp(ctrl.subscriber, ctrl.amount, ctrl.reason)
        .then(function() {
          ctrl.onDone()
        })
        .catch(function(err) {
          ctrl.error = err.error
        })
        .finally(function() {
          ctrl.loading = false
        })
    }
  }
})()
