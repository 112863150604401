;(function() {
  'use strict'

  SubscribersController.$inject = ["$scope", "breadcrumbsService", "plansService", "subscriberService", "glDialog", "glToast", "listFilterService", "$stateParams"];
  angular
    .module('app.subscribers')
    .controller('SubscribersController', SubscribersController)

  /* @ngInject */
  function SubscribersController(
    $scope,
    breadcrumbsService,
    plansService,
    subscriberService,
    glDialog,
    glToast,
    listFilterService,
    $stateParams
  ) {
    $scope.loading = false

    $scope.data = {
      subscribers: [],
    }

    // filters
    var uriFilters = listFilterService.initFilter($stateParams)
    $scope.filters = _.assign(
      {
        top: 50,
        page: 1,
        pageExhausted: false,
        orderby: 'createdAt',
        order: 'desc',
        keyword: '',
      },
      uriFilters
    )

    $scope.getSubscribers = getSubscribers
    $scope.getUsageDialog = getUsageDialog

    activate()

    /**
     * initialise
     */
    function activate() {
      plansService
        .list()
        .then(function(plans) {
          $scope.plans = plans
        })
        .then(getSubscribers)
      breadcrumbsService.addBreadcrumb('Subscribers', 'home')
    }

    function getUsageDialog(subscriberId) {
      var template = [
        '<gl-dialog class="usage__dialog">',
        '<usage',
        ' subscriber-id="subscriberId"',
        ' on-cancel="dialog.cancel()"',
        '/>',
        '</gl-dialog>',
      ]
      glDialog.show({
        template: template.join(' '),
        locals: {
          subscriberId: subscriberId,
        },
      })
    }

    /**
     * Build search object from filters
     * @param  {Object} srcObj filter object to build upon
     * @return {Object}
     */
    function organiseSearchObj(srcObj) {
      var sendingObj = {}

      // top, page, orderby
      sendingObj.top = srcObj.top
      sendingObj.skip = srcObj.top * (srcObj.page - 1)
      sendingObj.orderby = srcObj.orderby + ' ' + srcObj.order
      var filters = []
      // filter
      var keyword = srcObj.keyword
      if (keyword) {
        filters.push(
          'administrator.email regex /' +
            keyword +
            '/i or ' +
            "id eq '" +
            keyword +
            "' or " +
            'name regex /' +
            keyword +
            '/i or ' +
            'phoneNumber regex /' +
            keyword +
            '/i or ' +
            'subscriptionCode regex /' +
            keyword +
            '/i'
        )
      }
      if (filters.length) {
        sendingObj.filter = filters.join(' and ')
      }

      return sendingObj
    }

    /**
     * Get subscribers from server
     * @param  {Boolean} append if true then append to existing subscribers data set
     */
    function getSubscribers(append) {
      $scope.loading = true
      if (!append && $scope.data.subscribers) {
        $scope.data.subscribers = []
        $scope.filters.page = 1
        $scope.filters.pageExhausted = false
        listFilterService.writeFilter($scope.filters)
      } else if (append) {
        $scope.filters.page++
      }

      subscriberService
        .getAll(organiseSearchObj($scope.filters))
        .then(getSuccess, getError)

      function getSuccess(data) {
        data.forEach(function(subscriber) {
          subscriber.plan = $scope.plans.find(function(plan) {
            return plan.id === subscriber.planDetail.planId
          })
        })
        $scope.loading = false
        $scope.data.subscribers.push.apply($scope.data.subscribers, data)
        if (data.length < $scope.filters.top) {
          $scope.filters.pageExhausted = true
        }
      }

      function getError() {
        $scope.loading = false
        glToast.action(
          'Error retrieving subscriber.',
          'Retry',
          $scope.getSubscribers,
          {
            target: '.main-content',
            timeout: 5000,
          }
        )
      }
    }
  }
})()
